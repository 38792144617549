import { Avatar, Box, Button, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import ActionButton from "@components/atom/ActionButton";
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import PayRedModal from "@components/molecules/wallet-payment-red/PayRedModal";
import { useRef } from "react";
import { useUnsettleCase } from "@hooks/api/useWalletApi";

type Props = {};

export default function PayToRed({}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  const handleButtonClick = () => {
    onOpen();
  };

  return (
    <>
      <Flex bg={"white"} p={4} borderRadius={"xl"} shadow={"sm"} gap={3} alignItems={"start"} justify={"space-between"} borderWidth={1} overflow="hidden">
        <>
          <Box pos={"relative"}>
            <Avatar src="/assets/logo/apple-launch-1125x2436.png" borderWidth={1} borderColor={"red.100"} bg={"red.100"} p={1} size={"md"} className="blob" color="red" />
          </Box>
          <Text fontSize={"sm"} fontWeight={"normal"}>
            You can directly pay to the red.health
          </Text>
        </>
        <Button onClick={handleButtonClick} m={0} fontSize={"xs"} variant={"solid"} bg="red" borderRadius={"full"} px={5} size={"sm"} fontWeight={"semibold"}>
          Pay Red
        </Button>
      </Flex>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody padding={10}>
            <PayRedModal />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
