import { Button, Center, Stack, Text } from "@chakra-ui/react";
import { useUnsettleCase } from "@hooks/api/useWalletApi";
import { useRedStore } from "@store/store";
import { convertPrice, convertToCurrency } from "@utils";
import { useNavigate } from "react-router-dom";

export default function PayRedModal() {
  const { redPay, data, ...rest } = useUnsettleCase({ onSuccess: handleSuccess });
  const selectedOutstanding = useRedStore(state => state.selectedOutstanding);
  const navigate = useNavigate();
  const resp: any = data?.data;

  function handleSuccess() {}

  const totalAmount = selectedOutstanding?.reduce((acc, itm) => acc + itm.amount, 0);

  const handleGeneratePayableLink = () => {
    if (resp?.paymentLink) {
      window?.open(resp?.paymentLink, "_blank");
      navigate("/");
    } else {
      redPay();
    }
  };

  return (
    <Center>
      <Stack spacing={4} justify={"space-between"} align={"center"} minH={"25vh"}>
        <Center flexDir={"column"} textAlign={"center"}>
          <Text fontSize={"5xl"} fontWeight={"semibold"} color={"black"}>
            {convertToCurrency(convertPrice(totalAmount))}
          </Text>
          <Text fontSize={"sm"} fontWeight={"normal"}>
            You are paying this amount directly to Red.health. By clicking the button, you can generate a payable link.
          </Text>
        </Center>
        <Button
          px={8}
          bg={resp?.paymentLink ? "green" : "#151f21"}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={handleGeneratePayableLink}
          w={"full"}
          isLoading={rest.isLoading}
          // isDisabled={!isDisable}
        >
          {resp?.paymentLink ? "Pay Now" : "Generate Payable Link"}
        </Button>
      </Stack>
    </Center>
  );
}
