import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Tag,
  Text,
  Wrap,
  useColorModeValue,
  useDisclosure,
  useSteps,
} from "@chakra-ui/react";
import { useMdmUserSearch } from "@hooks/api/useMdm";
import { useRedStore } from "@store/store";
import { convertPrice, convertToCurrency, objToQuery, secondsToHoursAndMinutes, showSuccessToast } from "@utils";
import { useCountdown, useDebounce } from "usehooks-ts";
import AsyncSelect from "react-select/async";
import { getMdmUser } from "api/service/mdm";
import { debounce } from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useGeneratePaymentOtp } from "@hooks/api/useWalletApi";
import { useNavigate } from "react-router-dom";
import { pageView, walletSendOtpButtonClicked, walletVerifyAndCollectButtonClicked } from "@config/event";
import PayToRed from "@components/molecules/wallet-payment-red/PayToRed";
import { ENABLED_USER_ROLES_FOR_ONLINE_PAY } from "@config/constant";

const steps = [
  { title: "First", description: "Confirm Ouststanding" },
  { title: "Second", description: "Date & Time" },
];

export default function PayOutstanding() {
  return (
    <Center>
      <CollectionAgentSection />
    </Center>
  );
}

const CollectionAgentSection = () => {
  const loginUser = useRedStore(state => state.user);
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const selectedOutstanding = useRedStore(state => state.selectedOutstanding);
  const totalOutstandingAmount = selectedOutstanding?.reduce((a, b) => a + b.amount, 0);
  const { sendOtp, verifyOtp, otpVerifyStatus, ...rest } = useGeneratePaymentOtp();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mClass, setMClass] = useState("");
  const [selectedCollectionAgent, setSelectedCollectionAgent] = useState({
    label: "",
    otp: "",
    otpToken: "",
    expiryTimeInSecs: 0,
    value: {
      creditorId: "",
      mobileNumber: "",
    },
  });

  const handleCollectionAgentSelect = (e: any) => {
    console.log(e);
    setSelectedCollectionAgent(e);
  };

  const promiseOptions = useCallback(
    debounce((inputValue, callback) => {
      if (inputValue?.length < 3) {
        return callback([]);
      }
      const query = {
        eligibleRole: "Collection Agent",
        name: inputValue,
        limit: 20,
      };
      getMdmUser(objToQuery(query)).then((data: any) => {
        console.log("dosososo: ", data);
        if (data && Array?.isArray(data)) {
          callback(data);
        } else {
          callback([]);
        }
      });
    }, 300),
    []
  );

  const onPinComplete = (otp: string) => {
    setSelectedCollectionAgent(prev => ({ ...prev, otp }));
  };

  const handleSendOtp = async () => {
    const payload = selectedCollectionAgent.value;
    walletSendOtpButtonClicked(payload);
    const response = await sendOtp(payload);
    setSelectedCollectionAgent(prev => ({ ...prev, ...response }));
    showSuccessToast("OTP send successfully to the Collection Agent number");
    setTimeout(() => {
      setMClass("translate(0px, -270px)");
    }, 0);
    onOpen();
  };

  const handleCancel = () => {
    setSelectedCollectionAgent(prev => ({ ...prev, otpToken: "" }));
    setMClass("translate(0px, 0px)");
    onClose();
  };

  const handleVerifyOtp = async () => {
    const payload = { ...selectedCollectionAgent?.value, otp: selectedCollectionAgent?.otp, token: selectedCollectionAgent?.otpToken };

    walletVerifyAndCollectButtonClicked(payload);
    const response = await verifyOtp(payload);
    // setSelectedCollectionAgent(prev => ({ ...prev, ...response }));

    console.log(response);
    showSuccessToast("Verify Successfully.");

    navigate(`/payment-success?amount=${convertPrice(totalOutstandingAmount)}&message=Payment Collect Successfully 😃`);
  };

  useEffect(() => {
    pageView("Pay Outstanding", {});
  }, []);

  return (
    <Stack gap={6}>
      {ENABLED_USER_ROLES_FOR_ONLINE_PAY.includes(`${loginUser?.primaryRole}`) && <PayToRed />}
      <Box opacity={isOpen ? 0.4 : 1} py={4} px={4} borderRadius={"xl"} bg={"white"} className="fade_in_animation">
        <Box>
          <Heading fontSize={"md"}>Select Collection Agent</Heading>
        </Box>
        <Divider borderStyle={"dotted"} my={4} />

        <Box>
          <Text fontSize={"xs"} mb={2}>
            Please type the collection agent's name; a minimum of 3 characters is required to perform a search.
          </Text>
          <AsyncSelect
            cacheOptions
            loadOptions={promiseOptions}
            className="basic-single"
            classNamePrefix="select"
            // value={currentCase?.requestedFor?.medicalIssue?.reason}
            isSearchable={true}
            name="color"
            placeholder="Type collection agent name .."
            onChange={handleCollectionAgentSelect}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 1000 }),
              control: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: 12,
                fontWeight: 500,
                minHeight: "30px",
              }),
              valueContainer: provided => ({
                ...provided,
                minHeight: "25px",
                padding: "2px 4px",
                marginTop: "0px",
                fontSize: "14px",
              }),
              input: provided => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "30px",
              }),
              option: (base: any) => ({
                ...base,
                zIndex: 9999,
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "0.02em",
                textTransform: "capitalize",
                backgroundColor: "white",
                color: "black",
                // height: 20,
              }),
            }}
          />
          <Text fontSize={"xs"} mb={1} mt={5}>
            An OTP will be sent to the collection agent's mobile number +91 {selectedCollectionAgent?.value.mobileNumber || "-"}
          </Text>
          <Button
            mt={1}
            px={8}
            bg={buttonColorValue}
            color={"white"}
            rounded={"md"}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            onClick={handleSendOtp}
            w={"full"}
            isDisabled={totalOutstandingAmount <= 0 || !selectedCollectionAgent?.value?.mobileNumber}
            fontSize={"sm"}
            fontWeight={"semibold"}
            isLoading={rest?.isLoading}
          >
            Send OTP
          </Button>
        </Box>
      </Box>
      {selectedCollectionAgent?.otpToken && (
        <Box
          transform={"auto"}
          transitionDuration={"2s"}
          py={4}
          style={{ transform: mClass, transitionDuration: "1s" }}
          px={4}
          borderRadius={"xl"}
          overflow={"hidden"}
          bg={"white"}
        // className="fade_in_animation"
        >
          <Box>
            <Flex justify={"space-between"} align={"center"}>
              <Heading fontSize={"md"}>OTP Verification</Heading>
              <Flex justify={"center"} align={"center"} onClick={handleCancel}>
                <Icon icon="material-symbols-light:cancel-outline" fontSize={24} color="dark" />
              </Flex>
            </Flex>
          </Box>
          <Divider borderStyle={"dotted"} my={4} />

          <>
            <Box>
              <Text fontSize={"xs"} mb={2}>
                Please provide the pin which is send to the colleciton agent mobile number - +91 {selectedCollectionAgent?.value?.mobileNumber}
              </Text>

              <HStack>
                <PinInput otp onChange={onPinComplete}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Box>
            {selectedCollectionAgent?.expiryTimeInSecs && !rest?.isLoading && (
              <CountDown
                onExpire={
                  <Tag onClick={handleSendOtp} colorScheme="green">
                    Resend OTP
                  </Tag>
                }
                label="Otp Will expire in "
                qrExpiryTimeInSecs={selectedCollectionAgent?.expiryTimeInSecs}
              />
            )}
            <Divider borderStyle={"dotted"} my={4} />
          </>
          <Button
            px={8}
            bg={buttonColorValue}
            color={"white"}
            rounded={"md"}
            isLoading={otpVerifyStatus?.isLoading}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            onClick={handleVerifyOtp}
            w={"full"}
            isDisabled={totalOutstandingAmount <= 0 || !selectedCollectionAgent?.otp || selectedCollectionAgent?.otp?.length < 4}
            fontSize={"sm"}
            fontWeight={"semibold"}
          >
            Verify And Collect
          </Button>
        </Box>
      )}
    </Stack>
  );
};

interface ICountDown {
  label: string;
  qrExpiryTimeInSecs: number;
  onExpire?: ReactElement;
}

const CountDown = ({ label, qrExpiryTimeInSecs, onExpire }: ICountDown) => {
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: qrExpiryTimeInSecs,
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
    return () => resetCountdown();
  }, []);

  return (
    <Box>
      <Flex mb={16} mt={5} align={"center"} gap={2} justify={"start"}>
        <Text fontSize={"xs"} color={"gray.500"} textAlign={"center"}>
          {label}
        </Text>
        <Text fontSize={"sm"} fontWeight={"semibold"} color={"black"}>
          {("0" + Math.floor(count / 60)).slice(-2)}:{("0" + Math.floor(count % 60)).slice(-2)}
        </Text>
        {onExpire && count === 0 && onExpire}
      </Flex>
    </Box>
  );
};
